<template lang="pug">
	v-container(grid-list-xl fluid)
		v-layout(row wrap)
			v-snackbar.ma-2(v-model='successAlert' :value='successAlert' :timeout='timeout' centered color='green')
				v-icon(left) check_circle
				span {{successAlertMsg}}
			v-snackbar.ma-2(v-model='errorAlert' :value='errorAlert' :timeout='timeout' centered color='red')
				v-icon(left) cancel
				span {{errorAlertMsg}}
			v-flex(lg4 sm12 xs12)
				v-simple-table.dataTableWrap
					template(v-slot:top)
						v-toolbar(flat)
							v-dialog(v-model='dialog' max-width='500px')
								template(v-slot:activator='{ on }')
									v-btn.defult-btn-color(dark rounded v-on='on') {{$t('admin.add')}}
								v-card
									v-card-title
										v-col(cols='12' sm='12' md='12')
											span.text-center(class='headline') {{$t('admin.addGroup')}}
										v-col(cols='12' sm='12' md='12')
											v-text-field(v-model='newGroupName' :label="$t('admin.groupName')")
								v-card-actions
										div(class='flex-grow-1')
										v-btn.defult-btn-color(dark rounded @click='close') {{$t('admin.cancel')}}
										v-btn.defult-btn-color(dark rounded @click='addGroup') {{$t('admin.save')}}
					v-data-table.dataTableWrap(:headers='groupHeaders' :items='group' :page.sync='page' :items-per-page='itemsPerPage' @page-count='pageCount = $event' @click:row='changeGroupName' hide-default-footer dense)
					v-pagination(v-model='page' :length='pageCount')
			v-flex(lg8 sm12 xs12)
				v-tabs(v-model='tab' color='#fff' background-color='transparent')
					v-tab(v-for='item in tabTitle' :key='item') {{ item }}
					v-tab-item
						v-card(flat)
							v-card-text
								span {{$t('admin.groupName')}}
								v-text-field(v-model='groupName' solo clearable background-color='grey darken-3')
								v-btn.defult-btn-color(dark rounded @click='saveGroupName') {{$t('admin.save')}}
					v-tab-item
						v-card(flat)
							v-card-text
								v-treeview(v-model='selectionItems' :items='items' selection-type='independent' open-on-click activatable selectable dense dark selected-color='teal')
								v-btn.defult-btn-color(@click='saveItems' rounded dark style='width:100%;') {{$t('admin.save')}}
					v-tab-item
						v-card(flat)
							v-card-text
								v-treeview(v-model='selectionDomain' :items='domains' selection-type='leaf' open-on-click activatable selectable dense dark selected-color='teal')
								v-btn.defult-btn-color(@click='saveDomains' rounded dark style='width:100%;') {{$t('admin.save')}}
					v-tab-item
						v-card(flat)
							v-card-text
								v-data-table(v-model='selectionOperator' item-key='operatorAC' :headers='headers' :items='operatorList' :items-per-page='10' show-select single-select=false)
									template(v-slot:top)
										v-toolbar(flat)
											v-dialog(v-model='dialog2' max-width='500px')
												template(v-slot:activator='{ on }')
													v-btn.defult-btn-color(dark rounded v-on='on') {{$t('admin.add')}}
												v-card
													v-card-title
														span.text-center(class='headline') {{$t('admin.pleaseSelectOperator')}}
													v-row
														v-col(cols='12')
															v-data-table(v-model='selectionWaiter' item-key='operatorAC' :headers='headers' :items='waitingList'
																:items-per-page='10' show-select single-select=false)
																template(v-slot:item.enable='{ item }')
																	span(v-if='item.enable')
																		v-icon(large color='green lighten-1') check
														v-col.d-flex.justify-space-between
															v-btn.mr-3(color='grey darken-2' dark rounded @click='close') {{$t('admin.cancel')}}
															v-btn.defult-btn-color(dark rounded @click='addOperator') {{$t('admin.add')}}
											v-dialog(v-model='dialog3' max-width='500px')
												template(v-slot:activator='{ on }')
													v-btn.defult-btn-color(dark rounded v-on='on') {{$t('admin.delete')}}
												v-card
													v-card-title
														span.text-center(class='headline') {{$t('admin.areYouSureYouWantToDelete')}}
													v-col.d-flex.justify-space-between
														v-btn.mr-3(color='grey darken-2' dark rounded @click='close') {{$t('admin.cancel')}}
														v-btn.defult-btn-color(dark rounded @click='deleteOperator') {{$t('admin.delete')}}
									template(v-slot:item.enable='{ item }')
										span(v-if='item.enable')
											v-icon(large color='green lighten-1') check
										span(v-else)
											v-icon(large color='red lighten-1') close
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import {
	addGroup,
	addOperatorToGroup,
	deleteOperatorFromGroup,
	getAllDomainList,
	getAllItemList,
	getGroupInfo,
	getGroupList,
	getOperatorNotInGroup,
	updateDomain,
	updateGroupName,
	updateItem
} from '../../../api/Admin';
import statusCode from '../../../assets/constant/statusCode';

export default {
	data() {
		return {
			originalItems: [],
			originalDomain: [],
			selectionItems: [],
			selectionDomain: [],
			selectionOperator: [],
			selectionWaiter: [],
			dialog: false,
			dialog2: false,
			dialog3: false,
			tab: null,
			timeout: 1500,
			tabTitle: [this.$t('admin.setting'), this.$t('admin.itemList'), this.$t('admin.cmsList'), this.$t('admin.operator')],
			errorMessage: 'API connection ERROR',
			groupName: '',
			newGroupName: '',
			groupID: null,
			items: [],
			domains: [],
			group: [],
			errorAlert: false,
			errorAlertMsg: 'I am an error alert.',
			successAlert: false,
			successAlertMsg: 'I am a success alert.',
			groupHeaders:[
				{ text: this.$t('admin.groupID'), value: 'id', align: 'center', filterable: false, sortable: false},
				{ text: this.$t('admin.groupName'), value: 'name', sortable: false }
			],
			page: 1,
			pageCount: 0,
			itemsPerPage: 15,
			headers: [
				{
					text: this.$t('admin.operatorID'),
					align: 'left',
					sortable: false,
					value: 'operatorAC'
				},
				{ text: this.$t('admin.enable'), value: 'enable' }
			],
			operatorList: [],
			waitingList: []
		};
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog']),
		async initialize() {
			let obj = {};
			this.groupID = this.getOperator.groupID;
			const reqData = {
				groupID: this.groupID
			};
			const groupRes = await getGroupList(reqData);
			const itemRes = await getAllItemList(reqData);
			const domainRes = await getAllDomainList(reqData);
			const groupInfo = await getGroupInfo(reqData);
			if (groupRes.status === statusCode.STATUS_OK) {
				let result = groupRes.result.res;
				for (let i of result) {
					obj = {};
					obj.id = i.id;
					obj.name = i.groupName;
					this.group.push(obj);
				}
				this.groupName = this.group[0].name;
			}
			if (itemRes.status === statusCode.STATUS_OK) {
				let result = itemRes.result.res;
				for (let i of result) {
					if (!i.parentID) {
						this.items.push({
							id: i.itemCode,
							name: i.description,
							children: []
						});
					} else {
						this.items[this.items.length - 1].children.push({
							id: i.itemCode,
							name: i.description
						});
					}
				}
			}
			if (domainRes.status === statusCode.STATUS_OK) {
				let result = domainRes.result.res;
				let res = {};
				result.forEach(item => {
					if (item['area_id'] in res) {
						res[item['area_id']].children.push({
							id: item.cms_id,
							name: item.name
						});
					} else {
						res[item['area_id']] = {
							id: item['area_id'],
							name: item['area_id'],
							children: [{ id: item.cms_id, name: item.name }]
						};
					}
				});
				for (let i in res) {
					this.domains.push(res[i]);
				}
			}
			if (groupInfo.status === statusCode.STATUS_OK) {
				let result = groupInfo.result.res;
				let tmpItem = JSON.parse(JSON.stringify(result.item));
				let tmpDomain = JSON.parse(JSON.stringify(result.domain));
				let tmpOperator = JSON.parse(JSON.stringify(result.operator));
				for (let i of tmpItem) {
					if (i.enable) {
						this.originalItems.push(i.item_id);
						this.selectionItems.push(i.item_id);
					}
				}
				for (let i of tmpDomain) {
					this.originalDomain.push(i.cms_id);
					this.selectionDomain.push(i.cms_id);
				}
				for (let i of tmpOperator) {
					let obj = {
						operatorAC: i.operatorAC,
						enable: i.active
					};
					this.operatorList.push(obj);
				}
			}
			this.operatorNotInGroup();
		},
		async addGroup() {
			const reqData = {
				groupID: this.getOperator.groupID,
				groupName: this.newGroupName
			};
			const res = await addGroup(reqData);
			if (res.status === statusCode.STATUS_OK) {
				this.allClear();
				this.initialize();
				this.successMsg(this.$t('admin.addSuccess'));
			} else {
				this.errorMsg(this.$t('admin.addFail'));
			}
			this.close();
		},
		async changeGroupName(group) {
			this.groupName = group.name;
			this.groupID = group.id;
			this.originalItems = [];
			this.originalDomain = [];
			this.selectionItems = [];
			this.selectionDomain = [];
			this.selectionWaiter = [];
			this.selectionOperator = [];
			this.operatorList = [];
			let reqData = {
				groupID: this.groupID
			};
			let groupInfo = await getGroupInfo(reqData);
			if (groupInfo.status === statusCode.STATUS_OK) {
				let result = groupInfo.result.res;
				let tmpItem = JSON.parse(JSON.stringify(result.item));
				let tmpDomain = JSON.parse(JSON.stringify(result.domain));
				let tmpOperator = JSON.parse(JSON.stringify(result.operator));
				for (let i of tmpItem) {
					if (i.enable) {
						this.originalItems.push(i.item_id);
						this.selectionItems.push(i.item_id);
					}
				}
				for (let i of tmpDomain) {
					this.originalDomain.push(i.cms_id);
					this.selectionDomain.push(i.cms_id);
				}
				for (let i of tmpOperator) {
					let obj = {
						operatorAC: i.operatorAC,
						enable: i.active
					};
					this.operatorList.push(obj);
				}
				this.operatorNotInGroup();
			}
		},
		async saveGroupName() {
			const reqData = {
				groupID: this.groupID,
				groupName: this.groupName
			};
			const res = await updateGroupName(reqData);
			if (res.status === statusCode.STATUS_OK) {
				for (let i of this.group) {
					if (i.id === this.groupID) {
						i.name = this.groupName;
					}
				}
				this.successMsg(this.$t('admin.modifySuccess'));
			} else {
				this.errorMsg(this.$t('admin.modifyFail'));
			}
		},
		async saveItems() {
			let origin = new Set(this.originalItems);
			let selected = new Set(this.selectionItems);
			let needUpdate = this.originalItems.filter(e => {
				return !selected.has(e);
			});
			let needInsert = this.selectionItems.filter(e => {
				return !origin.has(e);
			});
			const reqData = {
				groupID: this.groupID,
				tmp1: needUpdate,
				tmp2: needInsert
			};
			const res = await updateItem(reqData);
			if (res.status === statusCode.STATUS_OK) {
				let result = res.result.res;
				this.originalItems = [];
				this.selectionItems = [];
				if (!_.isEmpty(result)) {
					for (let i of result) {
						if (i.enable) {
							this.originalItems.push(i.item_id);
							this.selectionItems.push(i.item_id);
						}
					}
					this.successMsg(this.$t('admin.modifySuccess'));
				}
			} else {
				this.setDialogShow(true);
				this.errorMsg(this.$t('admin.modifyFail'));
			}
		},
		async saveDomains() {
			let origin = new Set(this.originalDomain);
			let selected = new Set(this.selectionDomain);
			let needUpdate = this.originalDomain.filter(e => {
				return !selected.has(e);
			});
			let needInsert = this.selectionDomain.filter(e => {
				return !origin.has(e);
			});
			const reqData = {
				groupID: this.groupID,
				tmp1: needUpdate,
				tmp2: needInsert
			};
			const res = await updateDomain(reqData);
			if (res.status === statusCode.STATUS_OK) {
				let result = res.result.res;
				this.originalDomain = [];
				this.selectionDomain = [];
				if (!_.isEmpty(result)) {
					for (let i of result) {
						this.originalDomain.push(i.cms_id);
						this.selectionDomain.push(i.cms_id);
					}
				}
				this.successMsg(this.$t('admin.modifySuccess'));
			} else {
				this.setDialogShow(true);
				this.errorMsg(this.$t('admin.modifyFail'));
			}
		},
		async operatorNotInGroup() {
			this.waitingList = [];
			this.selectionWaiter = [];
			let reqData = {
				groupID: this.getOperator.groupID
			};
			const res = await getOperatorNotInGroup(reqData);
			if (res.status === statusCode.STATUS_OK) {
				let result = res.result.res;
				for (let i of result) {
					let obj = {
						id: i.id,
						operatorAC: i.operatorAC,
						enable: i.active
					};
					this.waitingList.push(obj);
				}
			}
		},
		async addOperator() {
			let obj = [];
			for (let i of this.selectionWaiter) {
				obj.push({
					operator_id: i.id,
					group_id: this.groupID
				});
			}
			let reqData = {
				groupID: this.getOperator.groupID,
				operatorInfo: obj
			};
			if (!_.isEmpty(obj)) {
				const res = await addOperatorToGroup(reqData);
				if (res.status === statusCode.STATUS_OK) {
					this.changeGroupName({
						id: this.groupID,
						name: this.groupName
					});
					this.close();
					this.successMsg(this.$t('admin.addSuccess'));
				} else {
					this.errorMsg(this.$t('admin.addFail'));
				}
			} else {
				let operatorMustBeSelected = this.$t('error.operatorMustBeSelected');
				this.errorDialogStatus(operatorMustBeSelected);
			}
		},
		async deleteOperator() {
			let obj = [];
			for (let i of this.selectionOperator) {
				obj.push(i.operatorAC);
			}
			const reqData = {
				groupID: this.getOperator.groupID,
				operatorGroupID: this.groupID,
				operatorAC: obj
			};
			if (!_.isEmpty(obj)) {
				let res = await deleteOperatorFromGroup(reqData);
				if (res.status === statusCode.STATUS_OK) {
					this.changeGroupName({
						id: this.groupID,
						name: this.groupName
					});
					this.close();
					this.successMsg(this.$t('admin.deleteSuccess'));
				} else {
					this.errorMsg(this.$t('admin.deleteFail'));
				}
			} else {
				let operatorMustBeSelected = this.$t('error.operatorMustBeSelected');
				this.errorDialogStatus(operatorMustBeSelected);
			}
		},
		async successMsg(msg){
			this.successAlertMsg = msg;
			this.successAlert = true;
			this.errorAlert = false;
		},
		async errorMsg(msg){
			this.errorAlertMsg = msg;
			this.errorAlert = true;
			this.successAlert = false;
		},
		close() {
			this.dialog = false;
			this.dialog2 = false;
			this.dialog3 = false;
		},
		allClear() {
			this.originalItems = [];
			this.originalDomain = [];
			this.selectionItems = [];
			this.selectionDomain = [];
			this.selectionOperator = [];
			this.selectionWaiter = [];
			this.groupName = '';
			this.newGroupName = '';
			this.groupID = null;
			this.items = [];
			this.domains = [];
			this.group = [];
			this.operatorList = [];
			this.waitingList = [];
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		}
	},
	computed: {
		...mapGetters(['getOperator', 'getDialogShow', 'getStatusDialog'])
	},
	created() {
		this.initialize();
	}
};
</script>
